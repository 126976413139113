var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          staticStyle: { "padding-bottom": "10px!important" },
          attrs: { title: "상세" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-appr-btn", {
                    ref: "appr-btn",
                    attrs: {
                      name: "emergency-training-plan-appr-btn",
                      editable: _vm.editable,
                      approvalInfo: _vm.approvalInfo,
                    },
                    on: {
                      beforeApprAction: _vm.saveDataAppr,
                      callbackApprAction: _vm.approvalCallback,
                      requestAfterAction: _vm.getDetail,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-4 col-lg-4" },
              [
                _c("c-label-text", {
                  attrs: { title: "제목", value: _vm.issueCause.issuesTitle },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-3 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "대상년도",
                    value: _vm.issueCause.issuesYear,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-3 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "구분",
                    value: _vm.issueCause.issuesClassName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-3 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "작성자",
                    value:
                      _vm.issueCause.issuesDeptName +
                      "/" +
                      _vm.issueCause.issuesUserName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-3 col-md-2 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "작성일", value: _vm.issueCause.issuesDate },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "평가대상 목록",
                columns: _vm.grid.columns,
                gridHeight: _vm.gridHeight,
                data: _vm.issueCause.resultList,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && !_vm.disabled,
                selection: "multiple",
                rowKey: "issuesResultId",
              },
              on: { "table-data-change": _vm.tableDataChange },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "issuesTypeName"
                        ? [
                            _vm._v(
                              " " + _vm._s(props.row.issuesTypeName) + " "
                            ),
                            _vm.editable
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "q-btn-group",
                                      {
                                        staticClass: "ColumInnerBtnGroup",
                                        attrs: { outline: "" },
                                      },
                                      [
                                        _c(
                                          "q-btn",
                                          {
                                            staticClass: "ColumInnerBtn",
                                            attrs: {
                                              icon: "add",
                                              color: "red-6",
                                              "text-color": "white",
                                              align: "center",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.innerBtnClicked(
                                                  col,
                                                  props
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("q-tooltip", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "평가대상구분별 평가대상 추가"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "법령 추가",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addLaw },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "평가대상 추가",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addTarget },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable && !disabled",
                          },
                        ],
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.issueCause.resultList,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.deleteTarget },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }